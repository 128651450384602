import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Coaching Agreement`}</h2>
    <p><em parentName="p">{`updated Nov '24, subject to change`}</em></p>
    <h4>{`Overview`}</h4>
    <p>{`The following agreement defines the relationship between the `}<strong parentName="p">{`Coach`}</strong>{` (Ashutosh Desai) and the `}<strong parentName="p">{`Company`}</strong>{` (`}<strong parentName="p"><strong parentName="strong">{`_`}</strong></strong>{`). Coach will provide 6 months of coaching services to the company, by supporting the Company’s founder (`}<strong parentName="p"><strong parentName="strong">{`_`}</strong></strong>{`).`}</p>
    <h4>{`Coaching services`}</h4>
    <ul>
      <li parentName="ul">{`Two 90-minute sessions with founder each month.`}
        <ul parentName="li">
          <li parentName="ul">{`Every other week minus 2 holiday weeks (24/yr).`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Unlimited conversations via email or whatsapp.`}</li>
    </ul>
    <h4>{`Term & payment`}</h4>
    <ul>
      <li parentName="ul">{`6-month initial period (renewable after 6 months).`}</li>
      <li parentName="ul">{`Payment due monthly via Stripe invoices.`}</li>
      <li parentName="ul">{`Rates:`}
        <ul parentName="li">
          <li parentName="ul">{`Seed/A - $2-3k/mo`}</li>
          <li parentName="ul">{`Series B/C - $4-5k/mo`}</li>
        </ul>
      </li>
    </ul>
    <h4>{`Confidentiality`}</h4>
    <p>{`Coach agrees not to use any Confidential Information disclosed to Coach by the Company for Coach’s own use or for any purpose other than in service of the Company. Coach shall not disclose any Confidential Information of the Company to third parties. Company shall not disclose any Confidential Information shared by Coach to third parties. Coach is willing to sign Company’s standard NDA.`}</p>
    <h4>{`No conflict`}</h4>
    <p>{`Coach represents that this agreement does not create a conflict with obligations Coach has to any other person or entity. Coach will not enter into an agreement with a person or entity that creates a conflict with this agreement (eg. companies building directly competitive products/services).`}</p>
    <h4>{`Liability`}</h4>
    <p>{`Company assumes all responsibility of it’s decisions and actions, even when advised by Coach. Coach is not a decision making person at the Company cannot be held liable for the Company’s actions.`}</p>
    <h4>{`Termination`}</h4>
    <p>{`Either party can terminate the agreement at any time. If terminated by the Coach, payment will be pro-rated to the end of the prior month. If terminated by the Company, payment will be pro-rated to end of the current month.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      